<template>
  <b-container fluid>
    <b-row class="p-3">
      <b-col>
        <b-row class="d-flex" align-v="center">
          <h1 class="mr-5">Locations</h1>
          <div>
            <b-btn
              variant="primary"
              to="/admin/locations/create"
            >Add New</b-btn>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="p-3" align-v="center">
      <b-col sm="6">
        <b-nav pills>
          <b-nav-item
            v-for="option in statusOptions"
            :key="option.value"
            :active="option.value === filters.status"
            @click="filters.status = option.value"
          >{{ option.text }}</b-nav-item>
        </b-nav>
      </b-col>
      <b-col sm="6">
        <b-row class="justify-content-end">
          <b-col sm="3">
            <b-form-select
              v-model="filters.city_id"
              label="city"
              :options="cityOptions"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center p-3">
      <b-table
        responsive
        striped
        hover
        :items="items"
        :fields="fields"
      >
        <template #cell(name)="data">
          {{ data.value.en }}
        </template>
        <template #cell(short_address)="data">
          {{ data.value.en }}
        </template>
        <template #cell(status)="data">
          <span class="text-capitalize">{{ data.value }}</span>
        </template>
        <template #cell(actions)="data">
          <b-row>
            <b-link
              style="margin-right:5px;"
              :href="`/admin/locations/${data.item.id}`"
            >
              <i
                class="fas fa-edit fa-lg"
                title="Edit"
              />
            </b-link>
            <b-link
              v-if="data.item.status === 'active'"
              style="margin-right:5px;"
              @click.prevent="(toUpdate = data.item.id) && updateItem('paused')"
            >
              <i
                class="fas fa-pause fa-lg"
                style="color:orange;"
                title="Pause"
              />
            </b-link>
            <b-link
              v-if="data.item.status === 'paused'"
              style="margin-right:5px;"
              @click.prevent="(toUpdate = data.item.id) && updateItem('active')"
            >
              <i
                class="fas fa-play fa-lg"
                style="color:green;"
                title="Activate"
              />
            </b-link>
            <b-link
              v-if="data.item.status !== 'deleted'"
              @click.prevent="showDeleteModal(data.item.id)"
            >
              <i
                class="fas fa-trash fa-lg"
                style="color:red;"
                title="Delete"
              />
            </b-link>
            <b-link
              v-else
              @click.prevent="(toUpdate = data.item.id) && updateItem('active')"
            >
              <i
                class="fas fa-undo fa-lg"
                style="color:red;"
                title="Undo Delete"
            />
            </b-link>
          </b-row>
        </template>
      </b-table>
      <b-modal
        id="deleteModal"
        ref="deleteModal"
        title="Confirm Delete?"
        @ok="updateItem('deleted')"
      >This location will be deleted</b-modal>
    </b-row>
  </b-container>
</template>

<script>
import qs from 'qs'
import { makeGetRequest } from '@/util'

export default {
  name: 'LocationsList',
  data () {
    return {
      items: [],
      fields: [
        { key: 'id', label: 'Id', isRowHeader: true },
        { key: 'name', label: 'Name' },
        { key: 'short_address', label: 'Address' },
        { key: '__meta__.events_count', label: '# Events' },
        'status',
        'actions'
      ],
      statusOptions: [
        { value: 'active', text: 'Active' },
        { value: 'paused', text: 'Paused' },
        { value: 'deleted', text: 'Deleted' },
        { value: '', text: 'All' }
      ],
      city: null,
      filters: {
        status: 'active',
        city_id: null
      }
    }
  },
  methods: {
    async getItems() {
      const baseUrl = `${this.$config.apiUrl}/locations`
      const params = {
        counts: 'events'
      }
      Object.entries(this.filters).forEach(([key, value]) => {
        if (value != null) {
          params[key] = value
        }
      })
      const url = baseUrl + qs.stringify(params, {
        addQueryPrefix: true,
        encode: false,
        skipNulls: true
      })
      const items = await makeGetRequest(url)
      this.items = items
    },
    showDeleteModal(id) {
      this.toUpdate = id
      this.$refs.deleteModal.show()
    },
    async updateItem (status) {
      const { toUpdate } = this
      if (!toUpdate) return
      try {
        await this.axios.put(`${this.$config.apiUrl}/locations/${toUpdate}`, { status })
        const index = this.items.indexOf(
          this.items.find((i) => i.id === Number(toUpdate))
        )
        this.items[index].status = status
      } catch (error) {
        // console.error(error)
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getItems()
      }
    }
  },
  computed: {
    cityOptions() {
      return [
        { text: 'Select City', value: null },
        ...[...this.$root.cities].map(city => ({
          text: city.name.en,
          value: city.id
        }))
      ]
    }
  },
  mounted() {
    this.getItems()
  }
}
</script>

<style>
</style>
